export const team = [
  {
    name: "Barry Rothstein",
    role: "CEO / Founder",
    image: "barry",
  },
  // {
  //   name: "Karen Rothstein",
  //   role: "Cofounder",
  //   image: "barry",
  // },
  // {
  //   name: "Natalie Rothstein",
  //   role: "Squirrel",
  //   image: "barry",
  // },
];
import React, { useEffect, useRef } from "react";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import { team } from "../data/pages/about";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { graphql } from "gatsby";
import RisingWords from "../components/RisingWords";
import { assetArrayToObject } from "../lib/graphql-helpers";
import Button from "../components/Button";
gsap.registerPlugin(ScrollTrigger);

const About = ({ data }) => {
  const surfRef = useRef(null);
  const aboutPageRef = useRef(null);
  const businessRef = useRef(null);
  const pierRef = useRef(null);
  const animationsRef = useRef([]);

  const {
    team: { edges: teamImages },
  } = data;

  const sortedTeamImages = assetArrayToObject({
    useGatsbyImage: true,
    images: teamImages,
  });

  useEffect(() => {
    // Clear existing animations in case of component re-renders
    animationsRef.current.forEach((anim) => anim.scrollTrigger?.kill());
    animationsRef.current = []; // Clear stored animations

    if (aboutPageRef.current) {
      const risingTextAnimElements = Array.from(
        aboutPageRef.current.querySelectorAll(".rising-text-anim")
      );

      risingTextAnimElements.forEach((text) => {
        const subHeaderWords = Array.from(text.children);
        const wordElements = subHeaderWords.map((word) => word.children[0]);
        gsap.set(wordElements, { y: 40 });

        wordElements.forEach((wordElement, index) => {
          const animation = gsap.to(wordElement, {
            y: 0,
            duration: 1,
            delay: index * 0.03,
            scrollTrigger: {
              trigger: wordElement,
              start: "top bottom",
              end: "bottom top",
              toggleActions: "play none none reset",
            },
          });
          animationsRef.current.push(animation);
        });
      });
    }

    const createParallaxAnimation = (ref, triggerClass) => {
      if (ref.current) {
        const anim = gsap.fromTo(
          ref.current,
          { y: -400 },
          {
            y: 0,
            scrollTrigger: {
              trigger: triggerClass ? `.${triggerClass}` : ref.current,
              scrub: true,
              start: "top bottom",
              end: "bottom top-=400",
            },
          }
        );
        animationsRef.current.push(anim);
      }
    };

    createParallaxAnimation(surfRef, "surf-ref-class");
    createParallaxAnimation(businessRef, "business-ref-class");
    createParallaxAnimation(pierRef, null);

    if (aboutPageRef.current) {
      const leftTextElements = Array.from(
        aboutPageRef.current.querySelectorAll(".ri-anim-left")
      );
      const rightTextElements = Array.from(
        aboutPageRef.current.querySelectorAll(".ri-anim-right")
      );
      const bottomTextElements = Array.from(
        aboutPageRef.current.querySelectorAll(".ri-anim-bottom")
      );

      leftTextElements.forEach((ele) => {
        const anim = gsap.fromTo(
          ele,
          { x: -150, opacity: 0 },
          {
            x: 0,
            opacity: 1,
            duration: 1,
            ease: "power1.out",
            scrollTrigger: {
              trigger: ele,
              start: "top bottom",
              end: "bottom top",
            },
          }
        );
        animationsRef.current.push(anim);
      });

      rightTextElements.forEach((ele) => {
        const anim = gsap.fromTo(
          ele,
          { x: 150, opacity: 0 },
          {
            x: 0,
            opacity: 1,
            duration: 0.75,
            ease: "power1.out",
            scrollTrigger: {
              trigger: ele,
              start: "top bottom",
              end: "bottom top",
            },
          }
        );
        animationsRef.current.push(anim);
      });

      bottomTextElements.forEach((ele) => {
        const anim = gsap.fromTo(
          ele,
          { y: 150, opacity: 0 },
          {
            y: 0,
            opacity: 1,
            duration: 0.75,
            ease: "power1.out",
            scrollTrigger: {
              trigger: ele,
              start: "top bottom",
              end: "bottom top",
            },
          }
        );
        animationsRef.current.push(anim);
      });
    }
    // Cleanup ScrollTrigger and animation references on component unmount
    return () => {
      animationsRef.current.forEach((anim) => {
        anim.scrollTrigger?.kill(); // Properly kill each ScrollTrigger instance
        anim.kill(); // Kill the GSAP animation instance
      });
      animationsRef.current = []; // Clear the array of animations
    };
  }, []);
  return (
    <div
      ref={aboutPageRef}
      className="flex flex-col items-center justify-center w-full"
    >
      <section className="flex flex-col items-center justify-center w-full">
        <div className="w-full bg-white flex items-start justify-between max-w-screen-2xl py-32 px-8 box-border gap-12 relative">
          <RisingWords
            className={
              "text-black text-[70px] sm:text-[100px] lg:text-[120px] max-w-4xl lg:gap-4"
            }
            words={"Protecting you is our business."}
          />
        </div>
      </section>
      <section className="flex flex-col items-center justify-center w-full">
        <div className="w-full flex flex-col items-center justify-center max-w-screen-2xl rounded-md px-0 sm:px-8 py-18">
          <div className="flex flex-col md:flex-row w-full py-8 px-8 sm:px-4 items-center justify-between">
            <RisingWords
              className={"text-black text-4xl font-normal"}
              words={"But we don't do business as usual..."}
            />
            <div className="flex gap-2 items-center mt-6">
              <h5 className="text-lg text-black">Learn more</h5>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 24 24"
              >
                <path
                  fill="var(--black)"
                  d="M11 4h2v12l5.5-5.5l1.42 1.42L12 19.84l-7.92-7.92L5.5 10.5L11 16z"
                />
              </svg>
            </div>
          </div>
          <div className="flex items-start justify-start box-border w-full overflow-hidden rounded-[24px] h-[65%] relative rounded-custom">
            <div
              ref={surfRef}
              className="flex flex-col items-start justify-start w-full brightness-70 rounded-custom overflow-hidden surf-ref-class"
            >
              <StaticImage
                src="../images/about/surfing.jpg"
                alt="surfing"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                layout="fullWidth"
                placeholder="blurred"
                loading="lazy"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-col items-center justify-center w-full">
        <div className="flex flex-col xl:flex-row items-start justify-between max-w-screen-2xl rounded-md sm:px-8 py-8 sm:py-12 md:py-24 w-full gap-16">
          <div className="flex flex-col w-full justify-between gap-16 w-full xl:max-w-lg px-8 sm:px-0">
            <RisingWords
              className="text-black text-6xl font-normal"
              words={"Business Insurance Specialists"}
            />
            <div className="flex flex-col gap-8">
              <p className="text-lg font-medium text-darkerGrey ri-anim-left">
                Since 2005 our brokerage has experienced double digit growth.
                This growth is based upon our team’s commitment to our client’s
                well being.
              </p>
              <p className="text-lg font-medium text-darkerGrey ri-anim-left">
                We have a passion to look at the big picture for our clients
                including addressing current business insurance needs,
                contingency planning for the unexpected, and the development of
                a risk mitigating strategy.
              </p>
              <p className="text-lg font-medium text-darkerGrey ri-anim-left">
                We are a thriving, dynamic company filled with folks who really
                care. Every day we come to work, ready to dig in, asking
                ourselves how we can best serve you.
              </p>
              <p className="text-lg font-medium text-darkerGrey ri-anim-left">
                We’ve been a trusted partner to hundreds of organizations like
                yours, and countless individuals across the country.
              </p>
            </div>
          </div>
          <div className="relative overflow-hidden rounded-[20px] h-[33vh] sm:h-[34vh] md:h-[42vh] lg:h-[60vh] xl:h-[80vh] w-full">
            <div
              ref={businessRef}
              className="absolute top-0 left-1/2 w-[160vw] sm:w-full 2xl:w-[80vw] h-auto lg:h-full brightness-75 transform -translate-x-1/2"
            >
              <StaticImage
                src="../images/about/ventura-pier.jpg"
                alt="small businesses"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                layout="fullWidth"
                placeholder="blurred"
                loading="lazy"
                className="rounded-[24px]"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-col items-center justify-center w-full">
        <div className="flex flex-col-reverse xl:flex-row items-start justify-between max-w-screen-2xl rounded-md sm:px-8 py-8 sm:py-12 md:py-24 w-full gap-16">
          <div className="relative overflow-hidden rounded-[24px] h-[33vh] sm:h-[34vh] md:h-[42vh] lg:h-[60vh] xl:h-[80vh] w-full">
            <div
              ref={pierRef}
              className="absolute top-0 left-1/2 w-[160vw] sm:w-full 2xl:w-[80vw] h-auto lg:h-full brightness-75 transform -translate-x-1/2"
            >
              <StaticImage
                src="../images/about/blackSand.jpg"
                alt="ventura pier"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                layout="fullWidth"
                placeholder="blurred"
                loading="lazy"
                className=""
              />
            </div>
          </div>
          <div className="flex flex-col w-full justify-between gap-16 w-full xl:max-w-lg px-8 sm:px-0">
            <RisingWords
              className="text-black xl:text-8xl lg:text-6xl md:text-5xl sm:text-4xl text-3xl font-normal"
              words={"Comprehensive Insurance Solutions"}
            />
            <div className="flex flex-col gap-8">
              <p className="text-lg font-medium text-darkerGrey ri-anim-right">
                We collaborate with our partners to create and deliver smart and
                elegantly simple insurance solutions for all of your insurance
                needs, which reduce your total cost of risk and give you the
                competitive edge you need to succeed.
              </p>
              <p className="text-lg font-medium text-darkerGrey ri-anim-right">
                Time and time again, we’ve proven we know how to do insurance,
                we know how to do it well, and we’re not going anywhere.
              </p>
              <p className="text-lg font-medium text-darkerGrey ri-anim-right">
                We won’t rest until you love what we’ve done for you, and we
                feel an intense pride when we’ve contributed to your success.
              </p>
              <RisingWords
                className="text-2xl font-medium text-blue text-bold italic gap-0"
                words="It’s why we love coming to work for you."
              />
            </div>
          </div>
        </div>
      </section>
      <section className="flex flex-col items-center justify-center w-full">
        <div className="flex flex-col max-w-screen-2xl rounded-md px-8 py-8 sm:py-12 md:py-24 w-full gap-16">
          <div className="w-full items-center justify-center">
            <RisingWords
              className="text-5xl sm:text-8xl text-black flex items-center justify-center"
              words="Meet our founder"
            />
          </div>
          <div className="w-full grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-8">
            {team.map((member, index) => {
              const { name, role, image } = member;
              const animationClass =
                index % 3 === 0
                  ? "ri-anim-left"
                  : index % 3 === 1
                  ? "ri-anim-bottom"
                  : "ri-anim-right";

              return (
                <div
                  key={index}
                  className={`flex flex-col gap-4 ${animationClass}`}
                >
                  <GatsbyImage
                    image={sortedTeamImages[image]}
                    alt={name}
                    placeholder="blurred"
                    loading="lazy"
                    className="rounded-lg"
                  />
                  <div className="flex flex-col gap-1">
                    <h4 className="text-black text-xl font-medium">{name}</h4>
                    <h4 className="text-darkerGrey text-xl font-medium">
                      {role}
                    </h4>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="w-full flex flex-col items-center justify-center max-w-screen-2xl protect-image-class rounded-md bg-white">
        <div className="w-full bg-white box-border flex flex-col items-center justify-center max-w-screen-2xl py-8 sm:py-12 md:py-16 px-8 box-border gap-8 px-8">
          <div className="w-full flex-col sm:px-8 py-8 sm:py-12 md:py-16 rounded-[48px] flex items-center justify-center gap-12">
            <RisingWords
              words="Want to learn more?"
              className="text-black xl:text-8xl lg:text-6xl md:text-2xl sm:text-xl text-4xl"
            />
            <div className="w-full flex flex-col sm:flex-row items-center justify-center gap-4">
              <div className="w-full max-w-[300px]">
                <Button theme="hollow-large-black" to="/contact">
                  Get in touch.
                </Button>
              </div>
              <div className="w-full max-w-[300px]">
                <Button theme="large-blue" to="/industries">
                  Our services
                </Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;

export const query = graphql`
  query {
    team: allFile(
      filter: {
        extension: { eq: "jpg" }
        relativeDirectory: { eq: "about/team" }
      }
    ) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            gatsbyImageData(width: 420, placeholder: BLURRED)
            fluid {
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  }
`;
